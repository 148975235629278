import React from "react"

import Button from "../Button/Button"
import { CookToolsIcon } from "../common/Icons"
import LocalizedLink from "../common/LocalizedLink"
import Banner from "./Banner"
import s from "./WorkWithUsBanner.module.scss"

export default function WorkWithUsBanner({ bottomText, topText, buttonText }) {
  return (
    <Banner className={s.workWithUsBanner} variation="tallOffset">
      <CookToolsIcon className={s.icon} />
      <div className={s.textBlock}>
        <h3 className={s.topText}>{topText}</h3>
        <h3 className={s.bottomText}>{bottomText}</h3>
      </div>
      <LocalizedLink className={s.link} to="/careers/">
        <Button className={s.button} text={buttonText} />
      </LocalizedLink>
    </Banner>
  )
}

import classNames from "classnames"
import React from "react"

import Img from "../common/Img"
import LocalizedProductLink from "../common/LocalizedProductLink"
import Banner from "./Banner"
import s from "./ProductBanner.module.scss"

export default function ProductBanner({
  className,
  groupSlug,
  imageUrl,
  topText,
}) {
  return (
    <Banner className={classNames(s.productBanner, className)}>
      <LocalizedProductLink className={s.link} productGroupSlug={groupSlug}>
        <Img
          alt={topText}
          className={s.image}
          objectFit="contain"
          objectPosition="center"
          placeholderHidden
          src={imageUrl}
        />
        <div className={s.textBlock}>
          <h3
            className={s.topText}
            dangerouslySetInnerHTML={{ __html: topText }}
          />
        </div>
      </LocalizedProductLink>
    </Banner>
  )
}

const xs = 576
const sm = 768
const md = 992
const lg = 1200
const xl = 1360

export const mediaQueries = {
  xsUp: `(min-width:${xs}px)`,
  smUp: `(min-width:${sm}px)`,
  mdUp: `(min-width:${md}px)`,
  lgUp: `(min-width:${lg}px)`,
  xlUp: `(min-width:${xl}px)`,
}

import classNames from "classnames"
import React from "react"

import { FacebookIcon, InstagramIcon, LikeIcon } from "../common/Icons"
import Banner from "./Banner"
import s from "./SocialBanner.module.scss"

export default function SocialBanner({
  className,
  topText,
  bottomText,
  facebookLink,
  instagramLink,
}) {
  return (
    <Banner className={classNames(s.socialBanner, className)}>
      <LikeIcon className={s.backgroundIcon} />
      <div className={s.content}>
        <h3 className={s.title}>
          <div>{topText}</div>
          {bottomText}
        </h3>
        <SocialIcons
          facebookLink={facebookLink}
          instagramLink={instagramLink}
        />
      </div>
    </Banner>
  )
}

function SocialIcons({ facebookLink, instagramLink }) {
  return (
    <div className={s.iconsHolder}>
      {facebookLink && (
        <a
          aria-label="facebook"
          className={s.icon}
          href={facebookLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FacebookIcon />
        </a>
      )}
      {instagramLink && (
        <a
          aria-label="instagram"
          className={s.icon}
          href={instagramLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <InstagramIcon />
        </a>
      )}
    </div>
  )
}

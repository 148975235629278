import classNames from "classnames"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useMedia } from "react-use"

import { mediaQueries } from "../../constants/media"
import { ArrowThinIcon } from "../common/Icons"
import Img from "../common/Img"
import ImageHero from "../ImageHero/ImageHero"
import s from "./HeroCarousel.module.scss"

export const interval = 7000
export const transitionTime = 500

let touchStartX = 0
let touchEndX = 0

export default function HeroCarousel({
  className,
  imagesData,
  dotAlt,
  nextButtonAlt,
  prevButtonAlt,
}) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isMobile, setIsMobile] = useState(null)
  const [inProgress, setInProgress] = useState(false)
  const slidesHolderRef = useRef(null)
  const smUp = useMedia(mediaQueries.smUp, true)

  useEffect(() => {
    setIsMobile(testIsMobile)

    window.addEventListener("resize", () => {
      setIsMobile(testIsMobile)
    })

    return () =>
      window.removeEventListener("resize", () => {
        setIsMobile(testIsMobile)
      })
  }, [])

  useEffect(() => {
    if (imagesData.length === 1) return

    const sliderInterval = setInterval(() => goToNext(), interval)

    return () => {
      clearInterval(sliderInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide, inProgress])

  useLayoutEffect(() => {
    const slidesHolder = slidesHolderRef.current
    if (imagesData.length === 1) return

    slidesHolder.addEventListener("touchstart", onTouchStart, { passive: true })
    slidesHolder.addEventListener("touchend", onTouchEnd, { passive: true })
    Array.from(slidesHolder.querySelectorAll("img, picture")).forEach(
      disableDraggable
    )

    return () => {
      slidesHolder.removeEventListener("touchstart", onTouchStart)
      slidesHolder.removeEventListener("touchend", onTouchEnd)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide, inProgress])

  if (imagesData.length === 1) {
    return (
      <div className={classNames(s.heroCarousel, className)}>
        <ImageHero
          alt={imagesData[0].alt}
          className={s.imageDesktop}
          imageUrl={isMobile ? imagesData[0].mobile : imagesData[0].desktop}
        />
      </div>
    )
  }

  return (
    <div className={classNames(s.heroCarousel, className)}>
      <div
        aria-label={prevButtonAlt}
        className={s.prev}
        onClick={goToPrev}
        onKeyDown={goToPrev}
        role="button"
        tabIndex={0}
      >
        <ArrowThinIcon className={s.arrowLeft} />
      </div>
      <div className={s.slidesHolder} ref={slidesHolderRef}>
        {imagesData.map((image, index) => (
          <Slide
            activeIndex={currentSlide}
            index={index}
            key={image.desktop}
            {...image}
          />
        ))}
      </div>
      <div className={s.dotsHolder} {...getDotsHolderProps()}>
        {imagesData.map((image, index) => (
          <div
            className={classNames(s.dot, {
              [s.active]: index === currentSlide,
            })}
            key={image.desktop}
            style={{ transition: `${transitionTime}ms` }}
            {...getDotProps(index)}
          />
        ))}
      </div>
      <div
        aria-label={nextButtonAlt}
        className={s.next}
        onClick={goToNext}
        onKeyDown={goToPrev}
        role="button"
        tabIndex={0}
      >
        <ArrowThinIcon className={s.arrowRight} />
      </div>
    </div>
  )

  function testIsMobile() {
    return window.innerWidth <= 760
  }

  function goToPrev() {
    goTo(getPrevSlide())
  }

  function goToNext() {
    goTo(getNextSlide())
  }

  function goTo(slideIndex) {
    if (inProgress) return

    setInProgress(true)
    setCurrentSlide(slideIndex)

    setTimeout(() => {
      setInProgress(false)
    }, transitionTime)
  }

  function getPrevSlide() {
    return currentSlide - 1 >= 0 ? currentSlide - 1 : imagesData.length - 1
  }

  function getNextSlide() {
    return currentSlide + 1 < imagesData.length ? currentSlide + 1 : 0
  }

  function onTouchStart(event) {
    touchStartX = event.changedTouches[0].screenX
  }

  function onTouchEnd(event) {
    touchEndX = event.changedTouches[0].screenX

    if (touchStartX > touchEndX) {
      goToNext()
    } else if (touchStartX < touchEndX) {
      goToPrev()
    }

    touchStartX = 0
  }

  function disableDraggable(element) {
    element.setAttribute("draggable", false)
  }

  function getDotsHolderProps() {
    return smUp
      ? {}
      : {
          "aria-label": nextButtonAlt,
          onClick: goToNext,
          onKeyDown: goToNext,
          role: "button",
          tabIndex: 0,
        }
  }

  function getDotProps(index) {
    const alt = dotAlt && dotAlt.replace("{{index}}", index)
    return smUp
      ? {
          "aria-label": alt,
          onClick: () => goTo(index),
          onKeyDown: () => goTo(index),
          role: "button",
          tabIndex: 0,
        }
      : {}
  }
}

function Slide({ alt, desktop, mobile, index, activeIndex }) {
  return (
    <div
      className={classNames(s.slide, { [s.active]: index === activeIndex })}
      style={{ transition: `${transitionTime}ms` }}
    >
      <div>
        <Img
          alt={alt}
          className={s.imageDesktop}
          fadeIn={false}
          loading="eager"
          objectFit="cover"
          objectPosition="center"
          src={desktop}
        />
        {mobile ? (
          <Img
            alt={alt}
            className={s.imageMobile}
            fadeIn={false}
            loading="eager"
            objectFit="cover"
            objectPosition="center"
            src={mobile}
          />
        ) : null}
      </div>
    </div>
  )
}

import classNames from "classnames"
import React from "react"

import Button from "../Button/Button"
import LocalizedLink from "../common/LocalizedLink"
import Banner from "./Banner"
import s from "./CrownStandardBanner.module.scss"

export default function CrownStandardBanner({
  bottomText,
  buttonText,
  className,
  imageUrl,
  topText,
}) {
  return (
    <Banner
      alt={bottomText}
      backgroundUrl={imageUrl}
      className={classNames(s.crownStandardBanner, className)}
    >
      <div className={s.textBlock}>
        <h3 className={s.topText}>{topText}</h3>
        <h4 className={s.bottomText}>{bottomText}</h4>
      </div>
      <LocalizedLink className={s.link} to="/crown-standard/">
        <Button className={s.button} text={buttonText} />
      </LocalizedLink>
    </Banner>
  )
}

import classNames from "classnames"
import React from "react"

import { KingJrMealIcon } from "../common/Icons"
import Img from "../common/Img"
import LocalizedLink from "../common/LocalizedLink"
import Banner from "./Banner"
import s from "./KidsBanner.module.scss"

export default function KidsBanner({ className, imageUrl, topText }) {
  return (
    <LocalizedLink className={s.kidsBanner} to="/kids-menu/">
      <Banner
        className={classNames(s.banner, className)}
        variation="tallOffset"
      >
        <div className={s.textBlock}>
          <KingJrMealIcon />
        </div>
        <Img
          alt={topText}
          className={s.backgroundImage}
          objectFit="cover"
          objectPosition="bottom"
          src={imageUrl}
        />
      </Banner>
    </LocalizedLink>
  )
}

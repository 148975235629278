import classNames from "classnames"
import React from "react"

import Img from "../common/Img"
import s from "./Banner.module.scss"

export const variations = {
  default: "default",
  tall: "tall",
  tallOffset: "tallOffset",
}

export default function Banner({
  alt,
  className,
  children,
  backgroundUrl,
  variation = variations.default,
}) {
  const bannerClasses = classNames(
    s.banner,
    {
      [s.tall]: variation === variations.tall,
      [s.tallOffset]: variation === variations.tallOffset,
    },
    className
  )

  return (
    <div className={bannerClasses}>
      {backgroundUrl ? (
        <Img
          alt={alt}
          className={s.backgroundImage}
          objectFit="cover"
          objectPosition="center"
          src={backgroundUrl}
        />
      ) : null}
      {children}
    </div>
  )
}

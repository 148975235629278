import { graphql } from "gatsby"
import React from "react"

import CrownStandardBanner from "../components/Banners/CrownStandardBanner"
import KidsBanner from "../components/Banners/KidsBanner"
import MapBanner from "../components/Banners/MapBanner"
import ProductBanner from "../components/Banners/ProductBanner"
import SocialBanner from "../components/Banners/SocialBanner"
import WorkWithUsBanner from "../components/Banners/WorkWithUsBanner"
import HeroCarousel from "../components/HeroCarousel/HeroCarousel"
import Layout from "../components/Layout"
import MenuNav from "../components/MenuNav/MenuNav"
import SEO from "../components/seo"
import {
  selectHomePageContent,
  selectProductGroups,
  selectTranslations,
} from "../graphql/selectors"
import { findProductGroupSlugById } from "../utils/urls"
import s from "./index.module.scss"

export default function IndexPage(props) {
  const { data, pageContext } = props
  const { langCode, localePath, pagePathsByLangCode } = pageContext
  const { seo, hero, banners } = selectHomePageContent(data)
  const translations = selectTranslations(data)
  const productGroups = selectProductGroups(data)
  const productBannerGroupSlug = findProductGroupSlugById(
    productGroups,
    banners.productBanner.productGroupId
  )

  return (
    <Layout
      className={s.indexPage}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={productGroups}
    >
      <SEO {...seo} lang={langCode} />
      <MenuNav items={selectProductGroups(data)} />
      <HeroCarousel
        className={s.heroCarousel}
        dotAlt={hero.dotAlt}
        imagesData={hero.imagesData}
        nextButtonAlt={hero.nextButtonAlt}
        prevButtonAlt={hero.prevButtonAlt}
      />
      <div className={s.container}>
        <div className={s.bannersRow}>
          <div className={s.bannerHolder}>
            <ProductBanner
              {...banners.productBanner}
              groupSlug={productBannerGroupSlug}
            />
          </div>
          <div className={s.bannerHolder}>
            <MapBanner {...banners.mapBanner} />
          </div>
          <div className={s.bannerHolder}>
            <SocialBanner {...banners.socialBanner} />
          </div>
          <div className={s.bannerHolder}>
            <KidsBanner {...banners.kidsBanner} />
          </div>
          <div className={s.bannerHolder}>
            <CrownStandardBanner {...banners.crownStandardBanner} />
          </div>
          <div className={s.bannerHolder}>
            <WorkWithUsBanner {...banners.workWithUsBanner} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query IndexPage($locale: String) {
    ...HomePageFragment
    ...LayoutFragment
  }
`

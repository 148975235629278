import classNames from "classnames"
import React from "react"

import LocalizedLink from "../common/LocalizedLink"
import Banner from "./Banner"
import s from "./MapBanner.module.scss"

export default function MapBanner({ className, imageUrl, topText }) {
  return (
    <LocalizedLink className={s.mapBanner} to="/restaurants/">
      <Banner
        alt={topText}
        backgroundUrl={imageUrl}
        className={classNames(s.banner, className)}
        variation="tall"
      >
        <div className={s.textBlock}>
          <h4
            className={s.topText}
            dangerouslySetInnerHTML={{ __html: topText }}
          />
        </div>
      </Banner>
    </LocalizedLink>
  )
}

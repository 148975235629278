import classNames from "classnames"
import React from "react"

import s from "./Button.module.scss"

export const colors = {
  red: "red",
  grey: "grey",
}

export default function Button({
  color = colors.red,
  className,
  text,
  onClick,
}) {
  return (
    <button
      className={classNames(s.button, s[color], className)}
      onClick={onClick}
    >
      {text}
    </button>
  )
}
